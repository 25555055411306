
body {
    font-family: "Open Sans", sans-serif;
    padding: 0;
    margin: 0;
}

h1, h2, h3, .card-body>.card-title {
    font-family: "Kalam", cursive;
    color: white;
}

.btn-link {
    color: #f9e109;
}

.btn-link:hover {
    color: #EFA00B;
}

a {
    color: #f9e109;
  }
  
  a:hover {
    color: #EFA00B;
  }


.card-title {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.2em;
    color: #EFA00B;
}



.service-title {
    font-size: 1.2em;
    color: black;
}

.our-services {
    color: #bcc6cc;
}

.jumbotron {
    margin: 0;
    padding: 30px;
    background-image: linear-gradient(to right, #434343 0%, black 100%);
    color: white;
}

.subtext {
    font-family: Arial, Helvetica, sans-serif;
}


.services{
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    margin-top: 30rem;
}

.serviceCard{
    background: linear-gradient( to bottom, #bcc6cc, #eee, #bcc6cc);
    border-radius: 1.56rem;
    width: 10.94rem;
    height: 15rem;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em;
}

.serviceCard:hover {
    transform: scale(1.03);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
    opacity: 1;
  }


h2 {
    color: black;
}

.site-footer {
    padding: 20px 0;
    background-image: linear-gradient(to right, #434343 0%, black 100%);
    color: white;
}


.navbar-dark {
    background-image: linear-gradient(to right, #FFD194, #D1913C);
    border: solid;
}

.mr-auto {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 75px;
    margin-right: 150px;
}



.carousel-vid {
    width: 100%;
    height: 900px;
    object-fit: cover;
    border: none;
}

video {
    width: 100%;
    height: 56.25rem;
    object-fit: cover;
    border: none;
}


.content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    z-index: 2;
    margin-top: 20em;
}

.overlay {
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0;
    width: 100%;
    height: 60.625rem;
    background-color: rgba(0, 0, 0, 0.33);
    margin-top: 14.35rem;
}


.button {
    background-color: #EFA00B;
}


.google-map {
    margin-top: 150px;
    margin-bottom: 250px;
}

.faded-purp {
    width: 100%;
    height: 400px;
    border: none;
    background-color: #eef8ff; 
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.purp-info {
    height: 400px;
    border: none;
    background-color: #EFA00B; 
    padding: 40px;
}

.CardGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center
}

.persuasion-cards {
    margin-top: 100px;
    margin-bottom: 150px;
}



.founder-card {
    background-color: #EFA00B;
    max-height: 300px;
    padding: 20px;
    border-radius: 25px;
    position: absolute;
    bottom: 250px;
}

.founder-img {
    position: relative;
    bottom: 250px;
}

.info-form {
    position: relative;
    right: 80px;
}



@media (max-width: 1350px) {
    .serviceCard {
        width: 10.94rem;
        height: 15rem;
        margin: .50rem;
    }
    .service-title {
        font-weight: 500;
        font-size: 1.25em;
    }
}

@media (max-width: 1250px) {
    .serviceCard {
        width: 10.94rem;
        height: 15rem;
        margin: .25rem;
    }
    .service-title {
        font-weight: 500;
        font-size: 1.25em;
    }
    .overlay {
        margin-top: 16.5rem;
    }
    .founder-img {
        position: absolute;
        bottom: 300px;
    }
    .info-form {
        position: relative;
        right: 0px;
    }
}

@media (max-width: 1000px) {
    .serviceCard {
        width: 7rem;
        height: 9rem;
        margin: .5rem;
    }
    .service-title {
        font-weight: 500;
        font-size: .6rem;
    }
    .services {
        margin-top: 38rem;
    }
    .slogan {
        margin-top: 5rem;
    }
    .purp-info {
        height: 400px;
    }
    .overlay {
        margin-top: 20.2rem;
        height: 58rem;
    }
    .founder-img {
        position: relative;
        bottom: 655px;
    }
    .google-map {
        margin-bottom: 550px;
    }
    .info-form {
        position: relative;
        right: 0px;
    }
}

@media (max-width: 700px) {
    .purp-info {
        height: 500px;
    }
    .founder-img {
        position: relative;
        bottom: 635px;
    }
    .google-map {
        margin-bottom: 550px;
    }
    .services {
        margin-top: 32rem;
    }
    .slogan {
        font-size: 2rem;
        margin-top: 2rem;
    }
    .slogan-caption {
        font-size: 1rem;
    }
    .overlay {
        margin-top: 19.2rem;
        height: 58rem;
    }
}


@media (max-width: 500px) {
    .serviceCard {
        width: 6rem;
        height: 8rem;
        margin: .4rem;
    }
    .service-title {
        font-weight: 500;
        font-size: .6rem;
    }
    .founder-card {
        max-height: 350px;
    }
    .purp-info {
        height: 600px;
    }
    .content {
        font-size: 1.25rem;
    }
    .overlay {
        margin-top: 22.5rem;
        height: 59.2rem;
    }
    .services {
        margin-top: 39rem;
    }
    .slogan {
        font-size: 2rem;
        margin-top: 4rem;
    }
    .slogan-caption {
        font-size: 1rem;
    }
    .founder-img {
        position: relative;
        bottom: 710px;
        right: 15px;
    }
    .founder-card {
        padding: 20px;
        border-radius: 25px;
        position: absolute;
        bottom: 200px;
    }
    .google-map {
        margin-bottom: 550px;
    }
}